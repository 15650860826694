import React, { useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import emailjs from "@emailjs/browser";

import Footer from "../Footer";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
function CNCMachining() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const form = useRef();
  const sendEmail = (e) => {
    alert("message sent successfully");
    e.preventDefault();
    emailjs
      .sendForm(
        "service_ajpha2u",
        "template_srnoztp",
        form.current,
        "svc5eDpjvQRHX5DeK"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <>
      <section className="aboutsec1">
        <div className="fadeInUp wow" data-wow-duration="0.5s">
          <h3>{t("DesignPrototype")}</h3>
          <p>
            <Link to="/"> {t("Home")} / </Link>
            {t("DesignPrototype")}
          </p>
        </div>
      </section>
      <section className="servicesec1">
        <div className="service-details-area pt-100 pb-70">
          <Container>
            <Row>
              <Col md="4" lg="4" sm="12">
                <aside className="widget-area">
                  <div className="widget">
                    <h3 className="title">{t("ServiceCategory")}</h3>
                    <p>{t("ServiceCategory2")}</p>
                    <ul className="nav-list">
                      <li>
                        <Link to="/MoldMaking">{t("MoldMaking")}</Link>
                      </li>
                      <li>
                        <Link to="/CNCMachining">{t("CNCMachining")}</Link>
                      </li>
                      <li>
                        <Link to="/PlasticInjection">
                          {t("PlasticInjection")}
                        </Link>
                      </li>
                      <li className="active">
                        <Link to="/DesignPrototype">
                          {t("DesignPrototype")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Consultation">{t("Consultation")}</Link>
                      </li>
                    </ul>
                  </div>
                </aside>
              </Col>
              <Col md="8" lg="8" sm="12">
                <div className="service-details-desc">
                  <div className="service-details-slider owl-carousel owl-loaded owl-drag">
                    <div className="content">
                      <Swiper
                        navigation={true}
                        modules={[Navigation]}
                        className="mySwiper"
                      >
                        <SwiperSlide>
                          <img src="images/design (1).jpg" alt="" />
                        </SwiperSlide>
                        <SwiperSlide>
                          <img src="images/design (2).jpg" alt="" />
                        </SwiperSlide>
                        <SwiperSlide>
                          <img src="images/design (3).jpg" alt="" />
                        </SwiperSlide>
                      </Swiper>
                      <h2>{t("DesignPrototypeh2")}</h2>
                      <p>
                        <b>{t("DesignPrototypeb")}</b>
                      </p>
                      <p>{t("DesignPrototypep")}</p>
                    </div>
                    <div className="content">
                      <h2>{t("DesignPrototype")}</h2>
                    </div>
                    <div style={{ overflow: "hidden" }}>
                      <img src="images/design (3).jpg" alt="" />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <section className="contactsec2">
        <Container>
          <Row>
            <Col md="6" sm="12">
              <div className="slideInRight wow " data-wow-duration="0.5s">
                <div className="contactform">
                  <h3>{t("contacth3")} </h3>
                  <form ref={form} onSubmit={sendEmail}>
                    <div>
                      <p>{t("name")} *</p>
                      <input type="text" name="name" />
                    </div>
                    <div>
                      <p> {t("Company")}* </p>
                      <input type="text" name="company" />
                    </div>
                    <div>
                      <p>{t("Email")} *</p>
                      <input type="email" name="email" />
                    </div>
                    <div>
                      <p>{t("Phone")} *</p>
                      <input type="tel" name="phone" />
                    </div>
                    <div>
                      <p>{t("Message")} *</p>
                      <textarea name="message"></textarea>
                    </div>
                    <input type="submit" value="Send" />
                  </form>
                </div>
              </div>
            </Col>
            <Col md="6" sm="12">
              <div className="slideInLeft wow" data-wow-duration="0.5s">
               <iframe
                  title={t("ADDressDes")}
                  src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3458.3928297509783!2d30.86488917554865!3d29.91059107499127!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjnCsDU0JzM4LjEiTiAzMMKwNTInMDIuOSJF!5e0!3m2!1sen!2seg!4v1722806541602!5m2!1sen!2seg" width="100%"
                  height="450"
                  aria-hidden="true"
                  name={t("ADDressDes")}
                  loading="lazy"
                  ></iframe>
                <div>
                  <div className="mt-5">
                    <h5>{t("Address")}:</h5>
                    <p>{t("ADDressDes")}</p>
                  </div>
                  <div className="d-flex mt-5 flex-wrap">
                    <div style={{ marginRight: "20px" }}>
                      <h5>{t("Phone")}:</h5>
                      <p  style={{ direction: "ltr" }}>
                        +20 111 766 6193
                        <br />
                        +20 101 044 6661
                        <br />
                        +20 109 221 0110
                        <br />
                      </p>
                    </div>
                    <div>
                      <h5>{t("Email")}:</h5>

                      <p>
                      ahmedsultan@btwenty-factory.com<br />
                        b-twenty@btwenty-factory.com <br />
                        B.twenty1996@gmail.com
                      </p>
                    </div>
                  </div>
                </div>
             
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
}

export default CNCMachining;
