import React from "react";
import Container from "react-bootstrap/Container";
import { Row, Col } from "react-bootstrap";
import { AiFillPhone } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import { ImLinkedin2, ImLocation2 } from "react-icons/im";
import { MdEmail } from "react-icons/md";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <footer>
      <Container>
        <Row>
          <Col
            lg="3"
            md="3"
            sm="12"
            className="wow fadeInLeft span12 offcanlink"
            data-wow-duration="0.5s"
          >
            <img src="images/logoo.png" alt="" />
          </Col>
          <Col
            lg="3"
            md="3"
            sm="12"
            className="wow fadeInDown span12"
            data-wow-duration="0.5s"
          >
            <h5>{t("Links")}</h5>
            <div>
              <Link to="/">{t("Home")} </Link>
              <Link to="/About">{t("About")}</Link>
              <Link to="/ContactUs">{t("Contact")} </Link>
              <Link to="/Services">{t("Services")} </Link>
              <Link to="/Projects">{t("Projects")}</Link>
            </div>
          </Col>
          <Col
            lg="3"
            md="3"
            sm="12"
            className="wow fadeInUp span12"
            data-wow-duration="0.5s"
          >
            <h5>{t("Home4h5")}</h5>
            <p>{t("Home4h3")} </p>
            <div className="iconsfooter"></div>
          </Col>
          <Col
            lg="3"
            md="3"
            sm="12"
            className="wow fadeInRight span12"
            data-wow-duration="0.5s"
          >
            <h5>{t("ContactHere")}</h5>
            <div>
              <a href="#">
                <ImLocation2 /> {t("Address")}:
                <br />
                <div style={{ marginLeft: "50px" }}>{t("ADDressDes")}</div>
              </a>
              <a href="tel:+201117666193">
                <AiFillPhone /> {t("Phone")} :<br />
                <span style={{ marginLeft: "50px" }}>+20 111 766 6193</span>
                <br />
                <span style={{ marginLeft: "50px" }}>+20 101 044 6661</span>
                <br />
                <span style={{ marginLeft: "50px" }}>+20 109 221 0110</span>
                <br />
              </a>
              <a href="mailto:ahmedsultan@btwenty-factory.com">
                <MdEmail /> {t("Email")} :
                <br />
                <span style={{ marginLeft: "50px" }}>
                  ahmedsultan@btwenty-factory.com
                </span>
                <br />
                <span style={{ marginLeft: "50px" }}>
                  b-twenty@btwenty-factory.com
                </span>
                <br />
                <span style={{ marginLeft: "50px" }}>
                  B.twenty1996@gmail.com
                </span>
              </a>
            </div>
          </Col>
        </Row>
        <div className="copyRight">
          © 2023, B.Twenty Powered by{" "}
          <a
            href="https://website-builder-company.netlify.app/"
            target="_blank"
          >
            Aisha Tarek
          </a>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
