// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      Home: "Home",
      About: "About",
      contact: "Contact",
      projects: "Projects",
      dir: "ltr",
      Slide1Home:
        " B.Twenty provide an integrated solution that provide all services needed to bring your product to life, starting from product design to mass production.",
      Slide2HomeH: "Mold Making",
      Slide2Home:
        "We do our best to provide the most accurate molds that give our clients highly production rate with the optimum cost!",
      Slide3HomeH: "Plastic Injection Molding",
      Slide3Home:
        " To ensure the best quality for our client’s products,B.Twenty supports production (injection process) for the molds we made.",
      Slide4HomeH: "Consultations",
      Slide4Home:
        "  B.Twenty share the knowledge to help in making the right decision. If you didn't find what you need, please ask for private sessions.",
      Home2div1: "Manufacturing Custom Molds",
      Home2div2: "Technical consulting",
      Home2div3: "Mold maintenance",
      Home3h5: "Our molds achieve excellence in every detail",
      Home3h3:
        "We provide secure and efficient storage services for manufactured molds, with the ability to distribute them as  needed. We meticulously manage storage operations to ensure  product availability when required, contributing to the improvement of supply chains and reducing lead times.",
      Home3li1: "Quality Control System",
      Home3li2: "Innovative Engineers",
      Home3li3: "Environmental Sensitivity",
      Home3li4: "Professional and Qualified",
      Home4h5: "What do we do?",
      Home4h3: "Plastic injection molds to meet your quality preferences",
      Slide1Home4: "Mold Making",
      Slide1Home4p:
        "it is a process used in manufacturing to create replicas of a specific object or part. The primary purpose of mold making is to produce multiple identical copies of an original item,  ensuring consistency and efficiency in mass production. Here's a brief overview of the mold making process",
      Slide2Home4: "CNC Machining",
      Slide2Home4p:
        "CNC (Computer Numerical Control) machining is a manufacturing process that utilizes computerized controls and machine tools to remove material from a workpiece. It is a highly precise   and efficient method for producing complex parts and components. Here's a brief overview of CNC machining",
      Slide3Home4: "Plastic Production",
      Slide3Home4p:
        "it is a complex industrial process that involves the conversion of raw materials into various plastic products through a series of manufacturing steps. Here's a brief overview of the plastic production process",
      Slide4Home4: "Design & Prototyping",
      Slide4Home4p:
        "physical sensation differs greatly from seeing something on a screen, prototyping is an important step in the design      process. As a result, we create preliminary samples for our      clients to test product function, performance, and even shape.     To create a functional prototype, we use 3D printing or another suitable method",
      Slide5Home4: "Consultation",
      Slide5Home4p:
        "so everyone needs the knowledge and expert advice to make the right decision (for example; which plastic material is suitable for the product and for sure has a cost-effective?)",
      homesec33h3: "Why to choose Us ?",
      homesec33div1h5: "Design",
      homesec33div1p:
        "A good start is everything, so we use the most advanced design tools to get the highest accuracy.",
      homesec33div2h5: "Always On Time",
      homesec33div2p:
        "We can't say that we are the fastest! but we respect our client's time, so we deliver on time!",

      homesec33div3h5: "Support",
      homesec33div3p:
        "The project doesn't end at delivery ! B.Twenty will be your guide whenever you need support.",

      homesec33div4h5: "Picking",
      homesec33div4p:
        " Mold Making is a continuous chain. so, we pick everything based on a standard to get the best result from the cycle.",

      homesec33div5h5: "Advice",
      homesec33div5p:
        " By discussing our customers need; B.Twenty provides advice for the best solution, like mold size, best plastic material...etc.",

      homesec33div6h5: " Our fingerprint",
      homesec33div6p:
        " We try to keep our fingerprints on each mold or CNC  machining parts we do by using unique and suitable  techniques.",
      groupsecdiv1h3: "for Mold Making",
      groupsecdiv1li1:
        "Mold making especially plastic injection molds need highly manufacturing skills",
      groupsecdiv1li2:
        "We do our best to provide the most accurate molds that give our clients highly production rate with the optimum cost!",
      readmore: "Read More",
      groupsecdiv2h3: "For CNC Machining",
      groupsecdiv2li1:
        "CNC Machining is the most accurate machining way nowadays",
      groupsecdiv2li2: "we use CNC machines in almost all parts we do",
      groupsecdiv2li3: "our target is providing accurate parts to our clients",
      groupsecdiv2li4:
        "this confirms that all parts meet quality requirements.",
      groupsecdiv3h3: "For Plastic Production",
      groupsecdiv3li1:
        " To ensure the best quality for our client’s products,B.Twenty supports production (injection process) for the molds we made",
      groupsecdiv3li2:
        " you can get your final plastic product (in required  quantity) directly from our company without extra effort.",
      groupsecdiv4h3: "For Design & Prototyping",
      groupsecdiv4li1:
        " Because physical sensation differs greatly from seeing something on a screen prototyping is an important step in the design process",
      groupsecdiv4li2:
        " As a result, we create preliminary samples for our clients to test product function, performance, and even shape",
      groupsecdiv4li3:
        "  To create a functional prototype, we use 3D printing or  another suitable method.",
      groupsecdiv5h3: "For Consultation",
      groupsecdiv5li1:
        "The industry has a lot of obstacles, so everyone needs the  knowledge and expert advice to make the right decision (for example; which plastic material is suitable for the product and for sure has a cost-effective?).",
      groupsecdiv5li2:
        " As a result, we create preliminary samples for our clients to test product function, performance, and even shape",
      groupsecdiv5li3:
        "  To create a functional prototype, we use 3D printing or  another suitable method.",

      contacth3: "Contact US",
      name: "Name",
      Company: "Company",
      Email: "Email ",
      Phone: "Phone",
      Message: "Message",
      Address: "Address",
      Links: "Links",
      Home: "Home",
      About: "About",
      Contact: "Contact",
      Services: "Services",
      Projects: "Projects",
      ContactHere: "Contact Here",
      aboutsec1h3: "Why Choose B.Twenty",
      aboutsec2p: "About Us",
      aboutsec2h5: " Our molds achieve excellence in every detail",
      aboutsec2h3:
        "We provide high-precision services with attention to all details of manufactured templates, resulting in smooth operation on plastic machines.",

      aboutsec2li1: "Professional and creative designers",
      aboutsec2li2: "Innovative engineers",
      aboutsec4h31: "Missions",
      aboutsec4p1:
        "our mission is to be a leading force in the plastic molding industry, delivering precision, innovation, and reliability in every mold. Committed to client satisfaction and environmental responsibility, we aim to set new standards in plastic molding through continuous improvement and collaborative partnerships.",
      aboutsec4h32: "Values",
      aboutsec4li1: "works hard",
      aboutsec4li2: "works smart",
      aboutsec4li3: "works differently",
      servicesec1: "Our Services",
      projectsec1: "Our Projects",
      title: "name_en",
      description_en: "description_en",
      Contactsec1: "Contact US",
      servicesec1h2: "Technical Solutions",
      servicesec1p:
        "we specialize in providing cutting-edge technical solutions in the field of plastic molding.",
      CNCMachining: "CNC Machining",
      PlasticInjection: "Plastic Production",
      DesignPrototype: "Design & Prototyping",
      Consultation: "Consultation",
      MoldMakingp:
        "Mold Making services stand at the forefront of precision and innovation in the plastic molding industry ",
      CNCMachiningp:
        "our CNC Machining services epitomize cutting-edge precision and efficiency in the manufacturing industry.",
      PlasticInjectionp:
        "our Plastic Injection services embody efficiency, innovation, and precision in the complex realm of plastic product manufacturing. ",
      DesignPrototypep:
        "our Design & Prototyping services are the gateway to turning innovative ideas into tangible realities.",
      Consultationp:
        "our Consultation services are designed to be your guiding beacon in the intricate landscape of plastic molding and manufacturing.",
      ServiceCategory: "Service Category",
      ServiceCategory2: "Select a Service Category",

      MoldMakingh2: "How Mold Making Works?",
      MoldMakingb:
        "a meticulous and multi-step process that involves creating a precise and durable mold for use in various manufacturing applications",
      MoldMakingp:
        "Mold making is a critical step in the manufacturing process, influencing the quality and consistency of the final products. Precision, attention to detail, and a thorough understanding of the desired product are essential for successful mold making.",
      CNCMachiningh2: "How CNC Machining Works?",
      CNCMachiningb:
        " is a manufacturing process that utilizes computerized controls and machine tools to remove material from a workpiece. The process involves precision machining to create complex parts and components.",
      CNCMachiningp:
        "CNC machining offers high precision and repeatability, making it suitable for a wide range of industries and applications, from aerospace to automotive and beyond. The process's efficiency and versatility contribute to its widespread use in modern manufacturing.",
      PlasticInjectionh2: "How Plastic Injection Works?",
      PlasticInjectionb:
        "a versatile and widely used manufacturing process for producing plastic parts and products. The process involves melting plastic material and injecting it into a mold to shape the final product.",
      PlasticInjectionp:
        "Plastic injection molding is widely used in various industries due to its ability to produce high volumes of complex parts with precision. It is a versatile and efficient manufacturing process for creating a wide range of plastic products used in everyday life.",
      DesignPrototypeh2: "How Design Prototype Works?",
      DesignPrototypeb:
        "a crucial step in product development, allowing designers and engineers to create preliminary models that represent the final product. Prototypes are used to test and validate design concepts before mass production.",
      DesignPrototypep:
        "Design prototyping is an essential stage in the product development process, providing a tangible and testable representation of the product before it goes into full-scale production. It allows for adjustments, improvements, and ensures that the final product meets both functional and aesthetic requirements.",
      Consultationh2: "How Consultation Works?",
      Consultationb:
        "involves seeking expert advice and guidance to make informed decisions throughout the various stages of a project.",
      Consultationp:
        "Consultation plays a vital role in helping clients make well-informed decisions, optimize their processes, and navigate challenges in product development and manufacturing. It leverages the expertise of professionals to enhance the overall success of a project.",
      ADDressDes:
        "6 October city, makhzen elshabab Industrail, Factory  No. 746 ",
    },
  },
  es: {
    translation: {
      Home: " الرئيسيه",
      About: "الشركه",
      contact: "للتواصل",
      projects: "مشاريعنا",
      dir: "rtl",
      Slide1Home:
        "بي.تونتي تقدم حلاً متكاملاً يوفر جميع الخدمات اللازمة لإحياء منتجك، بدءًا من تصميم المنتج إلى الإنتاج الضخم. ",
      Slide2HomeH: "صناعة القوالب",
      Slide2Home:
        "نحن نبذل قصارى جهدنا لتوفير أفضل القوالب بدقة تامة، مما يضمن لعملائنا معدل إنتاج عالي بتكلفة مثلى! ",
      Slide3HomeH: " الحقن البلاستيكي      ",
      Slide3Home:
        "لضمان أفضل جودة لمنتجات عملائنا، تدعم بي.تونتي عملية الإنتاج (عملية الحقن) للقوالب التي قمنا بتصنيعها.  ",
      Slide4HomeH: "الاستشارات",
      Slide4Home:
        "بي.تونتي يشارك المعرفة للمساعدة في اتخاذ القرار الصحيح. إذا لم تجد ما تحتاج إليه، يرجى طلب جلسات خاصة. ",
      Home2div1: "تصنيع القوالب المخصصة ",
      Home2div2: "استشارات تقنية ",
      Home2div3: "صيانة القوالب",
      Home3h5: "تحقق قوالبنا التميز في كل تفصيل",
      Home3h3:
        "نقدم خدمات تخزين آمنة وفعالة للقوالب المصنعة، مع القدرة على توزيعها حسب الحاجة. ندير عمليات التخزين بدقة لضمان توافر المنتج عند الحاجة، مما يسهم في تحسين سلاسل التوريد وتقليل أوقات التسليم.",
      Home3li1: "نظام مراقبة الجودة",
      Home3li2: "مهندسون مبتكرون",
      Home3li3: "حساسية بيئية",
      Home3li4: "محترفون ومؤهلون",
      Home4h5: "ماذا نقوم به؟",
      Home4h3: "قوالب حقن البلاستيك لتلبية تفضيلات جودتك",
      Slide1Home4: "صناعة القوالب",
      Slide1Home4p:
        "إنه عملية تُستخدم في التصنيع لإنشاء نسخ مطابقة لكائن أو جزء محدد. الغرض الرئيسي من صناعة القوالب هو إنتاج نسخ متعددة متطابقة للعنصر الأصلي، مما يضمن التناسق والكفاءة في الإنتاج الضخم. فيما يلي لمحة عن عملية صناعة القوالب",
      Slide2Home4: "تصنيع CNC",
      Slide2Home4p:
        "تعتمد عملية التصنيع بالتحكم الرقمي على الحواسيب (CNC) على التحكم الحوسبي وأدوات الآلات لإزالة المواد من قطعة العمل. إنها طريقة دقيقة للغاية وفعالة لإنتاج أجزاء ومكونات معقدة. فيما يلي لمحة عن عملية تصنيع CNC",
      Slide3Home4: "إنتاج البلاستيك",
      Slide3Home4p:
        "إنها عملية صناعية معقدة تتضمن تحويل المواد الخام إلى منتجات بلاستيكية متنوعة من خلال سلسلة من الخطوات التصنيعية. فيما يلي لمحة عن عملية إنتاج البلاستيك",
      Slide4Home4: "التصميم والنمذجة",
      Slide4Home4p:
        "نظرًا لأن الإحساس الجسدي يختلف كثيرًا عن رؤية شيء على الشاشة، فإن عملية النمذجة تعد خطوة مهمة في عملية التصميم. نقوم بإنشاء عينات مبدئية لعملائنا لاختبار وظيفة المنتج وأدائه، وحتى الشكل. لإنشاء نموذج وظيفي، نستخدم الطباعة ثلاثية الأبعاد أو طريقة مناسبة أخرى",
      Slide5Home4: "الاستشارة",
      Slide5Home4p:
        "لذا يحتاج الجميع إلى المعرفة والنصيحة الفنية لاتخاذ القرار الصحيح (على سبيل المثال؛ أي مادة بلاستيكية مناسبة للمنتج وبتكلفة فعالة؟)",
      homesec33h3: "لماذا اختيارنا؟",
      homesec33div1h5: "التصميم",
      homesec33div1p:
        "البداية الجيدة هي كل شيء، لذلك نستخدم أدوات التصميم الأكثر تقدمًا للحصول على أعلى دقة.",
      homesec33div2h5: "دائمًا في الوقت المحدد",
      homesec33div2p:
        "لا يمكننا القول بأننا الأسرع! ولكننا نحترم وقت عملائنا، لذلك نقوم بتسليم الطلب في الوقت المناسب!",
      homesec33div3h5: "الدعم",
      homesec33div3p:
        "لا ينتهي المشروع عند التسليم! بي.تونتي ستكون مرشدك كلما احتجت إلى دعم.",
      homesec33div4h5: "الاختيار",
      homesec33div4p:
        "صناعة القوالب هي سلسلة مستمرة، لذلك نختار كل شيء بناءً على معايير للحصول على أفضل نتيجة من الدورة.",
      homesec33div5h5: "التوجيه",
      homesec33div5p:
        "من خلال مناقشة احتياجات عملائنا؛ تقدم بي.تونتي نصائح للحصول على أفضل حلاً، مثل حجم القالب، أفضل مادة بلاستيكية... إلخ.",
      homesec33div6h5: "بصمتنا",
      homesec33div6p:
        "نحاول الاحتفاظ ببصمتنا على كل قالب أو قطعة تصنيع CNC نقوم بها باستخدام تقنيات فريدة ومناسبة.",
      groupsecdiv1h3: "لصناعة القوالب",
      groupsecdiv1li1:
        "تحتاج صناعة القوالب خاصة قوالب حقن البلاستيك إلى مهارات تصنيع عالية",
      groupsecdiv1li2:
        "نحن نبذل قصارى جهدنا لتوفير أدق القوالب التي تمنح عملائنا معدل إنتاج عالي بتكلفة مثلى!",
      readmore: "اقرأ المزيد",
      groupsecdiv2h3: "التصنيع بالسي أن سي",
      groupsecdiv2li1:
        "تعتبر تصنيع CNC هي الطريقة الأكثر دقة في التصنيع في الوقت الحالي",
      groupsecdiv2li2: "نستخدم آلات CNC في معظم القطع التي نقوم بتصنيعها",
      groupsecdiv2li3: "هدفنا هو توفير قطع دقيقة لعملائنا",
      groupsecdiv2li4: "وهذا يؤكد أن جميع القطع تلبي متطلبات الجودة.",
      groupsecdiv3h3: "لإنتاج البلاستيك",
      groupsecdiv3li1:
        "لضمان أفضل جودة لمنتجات عملائنا، تدعم بي.تونتي عملية الإنتاج (عملية الحقن) للقوالب التي قمنا بتصنيعها",
      groupsecdiv3li2:
        "يمكنك الحصول على منتجك البلاستيكي النهائي (بالكمية المطلوبة) مباشرة من شركتنا دون جهد إضافي.",
      groupsecdiv4h3: "للتصميم والنمذجة",
      groupsecdiv4li1:
        "نظرًا لأن الإحساس الجسدي يختلف كثيرًا عن رؤية شيء على الشاشة، فإن عملية النمذجة تعد خطوة مهمة في عملية التصميم",
      groupsecdiv4li2:
        "نقوم بإنشاء عينات مبدئية لعملائنا لاختبار وظيفة المنتج وأدائه، وحتى الشكل",
      groupsecdiv4li3:
        "لإنشاء نموذج وظيفي، نستخدم الطباعة ثلاثية الأبعاد أو طريقة مناسبة أخرى.",
      groupsecdiv5h3: "للتشاور",
      groupsecdiv5li1:
        "تواجه الصناعة العديد من العقبات، لذلك يحتاج الجميع إلى المعرفة والنصيحة الفنية لاتخاذ القرار الصحيح (على سبيل المثال؛ أي مادة بلاستيكية مناسبة للمنتج وبتكلفة فعالة؟).",
      groupsecdiv5li2:
        "نتيجة لذلك، نقوم بإنشاء عينات مبدئية لعملائنا لاختبار وظيفة المنتج وأدائه، وحتى الشكل",
      groupsecdiv5li3:
        "لإنشاء نموذج وظيفي، نستخدم الطباعة ثلاثية الأبعاد أو طريقة مناسبة أخرى.",

      contacth3: "اتصل بنا",
      name: "الاسم",
      Company: "الشركة",
      Email: "البريد الإلكتروني",
      Phone: "الهاتف",
      Message: "الرسالة",
      Address: "العنوان",
      Links: "اهم الصفحات",
      Home: "الرئيسية",
      About: "الشركه",
      Contact: "التواصل",
      Services: "الخدمات",
      Projects: "المشاريع",
      ContactHere: "تواصل معنا",
      aboutsec1h3: "لماذا اختيار بي.تونتي",
      aboutsec2p: "الشركه",
      aboutsec2h5: "تحقق قوالبنا التميز في كل تفصيل",
      aboutsec2h3:
        "نقدم خدمات بدقه عاليه بكافه تفاصيل القوالب المصنعه مما يؤدى الى سلاسه فى التشغيل على ماكينات البلاستيك",
      aboutsec2li1: "مصممين محترفون ومبدعون",
      aboutsec2li2: "مهندسون مبتكرون",

      aboutsec4h31: "الرسالة",
      aboutsec4p1:
        "رسالتنا هي أن نكون قوة رائدة في صناعة القوالب البلاستيكية، نقدم الدقة والابتكار والموثوقية في كل قالب. نحن ملتزمون برضا العملاء والمسؤولية البيئية، ونهدف إلى وضع معايير جديدة في صناعة القوالب البلاستيكية من خلال التحسين المستمر والشراكات التعاونية.",
      aboutsec4h32: "القيم",
      aboutsec4li1: "يعمل بجد",
      aboutsec4li2: "يعمل بذكاء",
      aboutsec4li3: "يعمل بشكل مختلف",
      servicesec1: "خدماتنا",
      projectsec1: "مشاريعنا",
      title: "name_ar",
      description_en: "description_ar",
      Contactsec1: "تواصل معنا",
      servicesec1h2: "الحلول التقنية",
      servicesec1p:
        "نختص في تقديم حلول تقنية متقدمة في مجال صناعة القوالب البلاستيكية.",
      CNCMachining: "التصنيع بالسي أن سي",
      PlasticInjection: "إنتاج البلاستيك",
      DesignPrototype: "التصميم والنمذجة",
      Consultation: "الاستشارة",
      MoldMaking: "صنع القوالب",
      MoldMakingp:
        "خدمات صنع القوالب تقف في طليعة الدقة والابتكار في صناعة القوالب البلاستيكية.",
      CNCMachiningp:
        "خدمات تصنيع CNC لدينا تجسد الدقة والكفاءة الحديثة في صناعة التصنيع.",
      PlasticInjectionp:
        "تجسد خدمات حقن البلاستيك لدينا الكفاءة والابتكار والدقة في مجال تصنيع منتجات البلاستيك المعقدة.",
      DesignPrototypep:
        "خدمات التصميم والنمذجة لدينا هي بوابة لتحويل الأفكار المبتكرة إلى واقع ملموس.",
      Consultationp:
        "خدمات الاستشارة لدينا مصممة لتكون إشارة توجيهك في المشهد المعقد لصناعة القوالب البلاستيكية والتصنيع.",
      ServiceCategory: " الخدمات",
      ServiceCategory2: "حدد  الخدمة",
      MoldMakingh2: "كيفية عمل صنع القوالب؟",
      MoldMakingb:
        "عملية دقيقة ومتعددة الخطوات تشمل إنشاء قالب دقيق ومتين للاستخدام في تطبيقات التصنيع المختلفة.",
      MoldMakingp:
        "صنع القوالب خطوة حاسمة في عملية التصنيع، حيث تؤثر في جودة واتساق المنتجات النهائية. الدقة والاهتمام بالتفاصيل، وفهم شامل للمنتج المرغوب، هي أساسيات صنع القوالب الناجح.",
      CNCMachiningh2: "كيفية عمل التصنيع باستخدام الحاسب الآلي (CNC)؟",
      CNCMachiningb:
        "عملية تصنيع تستخدم التحكم الحاسوبي وأدوات الآلات لإزالة المواد من القطعة القابلة للتشغيل. تشمل العملية التصنيع بدقة لإنشاء أجزاء ومكونات معقدة.",
      CNCMachiningp:
        "يوفر تصنيع CNC دقة عالية وإعادة تكرار، مما يجعله مناسبًا لمجموعة واسعة من الصناعات والتطبيقات، من الفضاء إلى صناعة السيارات وما وراء ذلك. كفاءة العملية وتنوعها تسهم في انتشار استخدامها الواسع في التصنيع الحديث.",
      PlasticInjectionh2: "كيفية عمل حقن البلاستيك؟",
      PlasticInjectionb:
        "عملية تصنيع متنوعة ومستخدمة على نطاق واسع لإنتاج أجزاء ومنتجات من البلاستيك. تشمل العملية ذوبان مادة البلاستيك وحقنها في قالب لتشكيل المنتج النهائي.",
      PlasticInjectionp:
        "تُستخدم عملية حقن البلاستيك على نطاق واسع في مختلف الصناعات بسبب قدرتها على إنتاج حجوم كبيرة من الأجزاء المعقدة بدقة. إنها عملية تصنيع متعددة الاستخدامات وفعالة لإنتاج مجموعة واسعة من المنتجات البلاستيكية المستخدمة في حياتنا اليومية.",
      DesignPrototypeh2: "كيفية عمل نموذج التصميم؟",
      DesignPrototypeb:
        "خطوة حاسمة في تطوير المنتج، حيث تتيح للمصممين والمهندسين إنشاء نماذج أولية تمثل المنتج النهائي. يُستخدم نموذج التصميم لاختبار وتحقق من مفاهيم التصميم قبل الإنتاج الضخم.",
      DesignPrototypep:
        "نمذجة التصميم مرحلة أساسية في عملية تطوير المنتج، حيث توفر تمثيلًا قابلاً للامتحان والاختبار للمنتج قبل أن يدخل إلى إنتاج متنوع. إنها تسمح بإجراء تعديلات وتحسينات وتضمن أن المنتج النهائي يفي بالمتطلبات الوظيفية والجمالية.",
      Consultationh2: "كيفية عمل الاستشارة؟",
      Consultationb:
        "تشمل الاستشارة البحث عن نصائح وإرشادات من الخبراء لاتخاذ قرارات مستنيرة خلال مراحل مختلفة من المشروع.",
      Consultationp:
        "تلعب الاستشارة دورًا حيويًا في مساعدة العملاء على اتخاذ قرارات مستنيرة، وتحسين عملياتهم، والتنقل في التحديات في تطوير المنتج والتصنيع. إنها تستغل خبرة الفنيين لتعزيز النجاح الشامل للمشروع.",
      ADDressDes: "مدينة 6 أكتوبر - مخزن الشباب الصناعي - مصنع رقم 746",
    },
  },
  // Add more languages as needed
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en", // Default language
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
