import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaFingerprint,
  FaRegCalendarCheck,
} from "react-icons/fa";

import Footer from "./Footer";
import { Link } from "react-router-dom";
import {
  MdDesignServices,

} from "react-icons/md";
import {  IoIosTimer } from "react-icons/io";
import { BiCategoryAlt } from "react-icons/bi";
import { LuMessagesSquare } from "react-icons/lu";
import { RiShakeHandsLine } from "react-icons/ri";
import { useTranslation } from "react-i18next";

function About() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <>
      <section className="aboutsec1">
        <div className="fadeInUp wow" data-wow-duration="0.5s">
          <h3>{t("aboutsec1h3")}</h3>
          <p>
            <Link to="/"> {t("Home")} / </Link>
            {t("About")}
          </p>
        </div>
      </section>
      <section className="aboutsec2">
        <Container>
          <Row>
            <Col lg="6" md="6" sm="12">
              <div className="sec3imgdiv wow fadeInLeft" data-wow-duration="1s">
                <img
                  src="images/1.png"
                  alt=""
                  className="mainimg"
                />
                <img
                  src="images/Untitled-design-6-min.png"
                  alt=""
                  className="img1"
                />
                <div className="yearsdiv">
                  <h3>25</h3>
                  <p>Years of Success</p>
                </div>
              </div>
            </Col>
            <Col lg="6" md="6" sm="12">
              <div className="wow fadeInRight" data-wow-duration="1s">
                <p>{t("aboutsec2p")}</p>
                <h5> {t("aboutsec2h5")}</h5>
                <h3>{t("aboutsec2h3")}</h3>
                <ul>
                  <li>
                    <BiCategoryAlt />
                    <p>{t("aboutsec2li2")} </p>
                  </li>
                  <li>
                    <BiCategoryAlt />
                    <p>{t("aboutsec2li1")}</p>
                  </li>
 
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="homesec33">
        <Container>
          <h3 className="fadeInDown wow span12" data-wow-duration="0.5s">
            {t("homesec33h3")}
          </h3>
          <Row>
            <Col lg="6" md="6" sm="12">
              <div className="fadeInUp wow" data-wow-duration="0.5s">
                <MdDesignServices />
                <div>
                  <h5>{t("homesec33div1h5")}</h5>
                  <p>{t("homesec33div1p")}</p>
                </div>
              </div>
            </Col>
            <Col lg="6" md="6" sm="12">
              <div className="fadeInUp wow" data-wow-duration="0.5s">
                <IoIosTimer />
                <div>
                  <h5>{t("homesec33div2h5")}</h5>
                  <p>{t("homesec33div2p")}</p>
                </div>
              </div>
            </Col>
            <Col lg="6" md="6" sm="12">
              <div
                className="fadeInUp wow"
                data-wow-duration="0.5s"
                data-wow-delay="0.3s"
              >
                <RiShakeHandsLine />
                <div>
                  <h5>{t("homesec33div3h5")}</h5>
                  <p>{t("homesec33div3p")}</p>
                </div>
              </div>
            </Col>
            <Col lg="6" md="6" sm="12">
              <div
                className="fadeInUp wow"
                data-wow-duration="0.5s"
                data-wow-delay="0.4s"
              >
                <FaRegCalendarCheck />
                <div>
                  <h5> {t("homesec33div4h5")} </h5>
                  <p>{t("homesec33div4p")}</p>
                </div>
              </div>
            </Col>
            <Col lg="6" md="6" sm="12">
              <div
                className="fadeInUp wow"
                data-wow-duration="0.5s"
                data-wow-delay="0.3s"
              >
                <LuMessagesSquare />{" "}
                <div>
                  <h5>{t("homesec33div5h5")}</h5>
                  <p>{t("homesec33div5p")}</p>
                </div>
              </div>
            </Col>
            <Col lg="6" md="6" sm="12">
              <div
                className="fadeInUp wow"
                data-wow-duration="0.5s"
                data-wow-delay="0.4s"
              >
                <FaFingerprint />{" "}
                <div>
                  <h5>{t("homesec33div6h5")}</h5>
                  <p>{t("homesec33div6p")}</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <br />
      <br />

      <section id="mission" className="aboutsec4">
        <Container>
          <Row>
            <Col lg="6" md="6" sm="12">
              <div className="slideInRight wow" data-wow-duration="0.5s">
                <h3>{t("aboutsec4h31")}</h3>
                <p>
                {t("aboutsec4p1")}
                </p>
                <h3>{t("aboutsec4h32")}</h3>
                <ul>
                  <li>{t("aboutsec4li1")}</li>
                  <li>{t("aboutsec4li2")}</li>
                  <li>{t("aboutsec4li3")}</li>
                </ul>
              </div>
            </Col>
            <Col lg="6" md="6" sm="12">
              <img
                className="slideInLeft wow"
                data-wow-duration="0.5s"
                src="images/swrwcp vision and mission.jpg"
                alt=""
              />
            </Col>
          </Row>
        </Container>
      </section>
      <br />
      <br />
      <section className="groupsec " id="DiarBusiness">
        <Container fluid>
          <Row className="align-items-center">
            <Col md="6" sm="12" className="text-center">
              <img
                className="slideInLeft wow"
                data-wow-duration="0.5s"
                src="images/projects (3).jpeg"
                alt=""
              />
            </Col>
            <Col md="6" sm="12" className="grouptext">
              <div className="slideInRight wow" data-wow-duration="0.5s">
                <h3>
                  <span>B.Twenty</span> {t("groupsecdiv1h3")}
                </h3>
                <ul>
                  <li>
                    <BiCategoryAlt />

                    <span>{t("groupsecdiv1li1")}</span>
                  </li>
                  <li>
                    <BiCategoryAlt />

                    <span>{t("groupsecdiv1li2")}</span>
                  </li>
                </ul>
                <Link to="/MoldMaking">{t("readmore")}</Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="groupsec bg" id="Diar">
        <Container fluid>
          <Row className="align-items-center">
            <Col md="6" sm="12" className="grouptext">
              <div className="slideInLeft wow" data-wow-duration="0.5s">
                <h3>
                  <span>B.Twenty</span> {t("groupsecdiv2h3")}
                </h3>
                <ul>
                  <li>
                    <BiCategoryAlt />

                    <span>{t("groupsecdiv2li1")}</span>
                  </li>
                  <li>
                    <BiCategoryAlt />

                    <span>{t("groupsecdiv2li2")}</span>
                  </li>
                  <li>
                    <BiCategoryAlt />

                    <span>{t("groupsecdiv2li3")}</span>
                  </li>
                  <li>
                    <BiCategoryAlt />

                    <span>{t("groupsecdiv2li4")}</span>
                  </li>
                </ul>

                <Link to="/CNCMachining">{t("readmore")}</Link>
              </div>
            </Col>
            <Col md="6" sm="12" className="text-center">
              <img
                className="slideInRight wow"
                data-wow-duration="0.5s"
                src="images/projects (4).jpeg"
                alt=""
              />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="groupsec " id="flash">
        <Container fluid>
          <Row className="align-items-center">
            <Col md="6" sm="12" className="text-center">
              <img
                src="images/projects (6).jpeg"
                className="slideInLeft wow"
                data-wow-duration="0.5s"
                alt=""
              />
            </Col>
            <Col md="6" sm="12" className="grouptext">
              <div className="slideInRight wow" data-wow-duration="0.5s">
                <h3>
                  <span>B.Twenty</span>
                  {t("groupsecdiv3h3")}{" "}
                </h3>
                <ul className="swiperp">
                  <li>
                    <BiCategoryAlt />

                    <span>{t("groupsecdiv3li1")}</span>
                  </li>
                  <li>
                    <BiCategoryAlt />

                    <span>{t("groupsecdiv3li2")}</span>
                  </li>
                </ul>
                <Link to="/PlasticInjection">{t("readmore")}</Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="groupsec bg" id="pixels">
        <Container fluid>
          <Row className="align-items-center">
            <Col md="6" sm="12" className="grouptext">
              <div className="slideInLeft wow" data-wow-duration="0.5s">
                <h3>
                  <span>B.Twenty</span> {t("groupsecdiv4h3")}
                </h3>
                <ul>
                  <li>
                    <BiCategoryAlt />

                    <span>{t("groupsecdiv4li1")}</span>
                  </li>
                  <li>
                    <BiCategoryAlt />

                    <span>{t("groupsecdiv4li2")}</span>
                  </li>
                  <li>
                    <BiCategoryAlt />

                    <span>{t("groupsecdiv4li3")}</span>
                  </li>
                </ul>
                <Link to="/DesignPrototype">{t("readmore")}</Link>
              </div>
            </Col>
            <Col md="6" sm="12" className="text-center">
              <img
                className="slideInRight wow"
                data-wow-duration="0.5s"
                src="images/design.jpg"
                alt=""
              />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="groupsec " id="flash">
        <Container fluid>
          <Row className="align-items-center">
            <Col md="6" sm="12" className="text-center">
              <img
                src="images/Consultation.jpg"
                className="slideInLeft wow"
                data-wow-duration="0.5s"
                alt=""
              />
            </Col>
            <Col md="6" sm="12" className="grouptext">
              <div className="slideInRight wow" data-wow-duration="0.5s">
                <h3>
                  <span>B.Twenty</span> {t("groupsecdiv5h3")}
                </h3>
                <ul className="swiperp">
                  <li>
                    <BiCategoryAlt />

                    <span>{t("groupsecdiv5li1")}</span>
                  </li>
                </ul>
                <Link to="/Consultation">{t("readmore")}</Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <section className="aboutsec6" id="staff">
        <h2 className="fadeInDown wow span12" data-wow-duration="0.5s">
          Our Specialized Staff
        </h2>
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          slidesPerGroup={1}
          // loop={true}
          loopFillGroupWithBlank={true}
          autoplay={{ delay: 2000 }}
          modules={[Autoplay]}
          breakpoints={{
            640: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 4,
            },
          }}
          // modules={[Autoplay]}
          // className="mySwiper clientslider"
          className="mySwiper fadeInUp wow"
          data-wow-duration="0.5s"
        >
          <SwiperSlide>
            <div>
              <IoIosPerson />
              <h3>LOREM</h3>
              <p>CEO</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div>
              <IoIosPerson />
              <h3>LOREM</h3>
              <p>CEO</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div>
              <IoIosPerson />
              <h3>LOREM</h3>
              <p>CEO</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div>
              <IoIosPerson />
              <h3>LOREM</h3>
              <p>CEO</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div>
              <IoIosPerson />
              <h3>LOREM</h3>
              <p>CEO</p>
            </div>
          </SwiperSlide>
        </Swiper>
      </section> */}
      <Footer />
    </>
  );
}

export default About;
