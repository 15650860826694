import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import PulseLoader   from "react-spinners/PulseLoader";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Home from "./Home";
import NavBar from "./NavBar";
import About from "./About";
import ContactUs from "./ContactUs";
import Projects from "./Projects";
import NotFound from "./NotFound";
import Services from "./Services";
import PlasticInjection from "./Services/PlasticInjection";
import MoldMaking from "./Services/MoldMaking";
import DesignPrototype from "./Services/DesignPrototype";
import Consultation from "./Services/Consultation";
import CNCMachining from "./Services/CNCMachining";
import './App.css';
import { useTranslation } from "react-i18next";
import ScrollToTop from "./ScrollToTop";


function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {

    setLoading(true);
    setTimeout(() => {
      setLoading(false);

    }, 3000);
    
  }, [])
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <BrowserRouter>
      {loading ?
        <div className="preloaderr">
          <PulseLoader   color={'#fc5a34'} loading={loading} height={50} margin={5} />
        </div> 
        :
        <div  className={t("dir")}>
          <NavBar />

          <ScrollToTop />
          <Routes>
            <Route index  element={<Home />} />
            <Route path="/About" element={<About />} />
            <Route path="/ContactUs" element={<ContactUs />} />
            <Route path="/Projects" element={<Projects />} />
            <Route path="/Services" element={<Services />} />
            <Route path="/CNCMachining" element={<CNCMachining />} />
            <Route path="/Consultation" element={<Consultation />} />
            <Route path="/DesignPrototype" element={<DesignPrototype />} />
            <Route path="/MoldMaking" element={<MoldMaking />} />
            <Route path="/PlasticInjection" element={<PlasticInjection />} />


            <Route path="*" element={<NotFound />} />

          </Routes>
        </div>
      }
    </BrowserRouter>
  );
}

export default App;
