import React, {  useRef } from "react";
import Footer from "./Footer";
import { Container, Row, Col } from "react-bootstrap";
import {  FaPhoneAlt } from "react-icons/fa";

import { BsTelephone } from "react-icons/bs";
import { GoLocation } from "react-icons/go";
import { CiLocationOn } from "react-icons/ci";

import emailjs from "@emailjs/browser";
import { Link } from "react-router-dom";
import { MdEmail } from "react-icons/md";
import { useTranslation } from "react-i18next";
function ContactUs() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const form = useRef();
  const sendEmail = (e) => {
    alert("message sent successfully");
    e.preventDefault();
    emailjs
      .sendForm(
        "service_ajpha2u",
        "template_srnoztp",
        form.current,
        "svc5eDpjvQRHX5DeK"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <>
      <section className="aboutsec1">
        <div className="fadeInUp wow" data-wow-duration="0.5s">
          <h3> {t("Contactsec1")}</h3>
          <p>
            <Link to="/"> {t("Home")} / </Link> {t("Contactsec1")}
          </p>
        </div>
      </section>
      <section className="contactsec1">
        <Container>
          <Row>
            <Col lg="4" md="4" sm="12">
              <div>
                <div className="hoverdiv wow fadeInLeft" data-wow-duration="1s">
                  <div className="hoverdetail">
                    <FaPhoneAlt className="detailIcon" />
                    <p style={{ direction: "ltr", fontSize: "20px" }}>
                      {" "}
                      +20 111 766 6193
                      <br />
                      +20 101 044 6661
                      <br />
                      +20 109 221 0110
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg="4" md="4" sm="12">
              <div>
                <div className="hoverdiv wow fadeInUp" data-wow-duration="1s">
                  <div className="hoverdetail">
                    <MdEmail className="detailIcon" />
                    <p style={{ direction: "ltr", fontSize: "20px" }}>
                    ahmedsultan@btwenty-factory.com<br />  b-twenty@btwenty-factory.com <br /> B.twenty1996@gmail.com
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg="4" md="4" sm="12">
              <div>
                <div className="hoverdiv wow fadeInDown" data-wow-duration="1s">
                  <div className="hoverdetail">
                    <CiLocationOn className="detailIcon" />

                    <p style={{ fontSize: "20px" }}>{t("ADDressDes")}</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="contactsec2">
        <Container>
          <Row>
            <Col md="6" sm="12">
              <div className="slideInRight wow " data-wow-duration="0.5s">
                <div className="contactform">
                  <h3>{t("contacth3")} </h3>
                  <form ref={form} onSubmit={sendEmail}>
                    <div>
                      <p>{t("name")} *</p>
                      <input type="text" name="name" />
                    </div>
                    <div>
                      <p> {t("Company")}* </p>
                      <input type="text" name="company" />
                    </div>
                    <div>
                      <p>{t("Email")} *</p>
                      <input type="email" name="email" />
                    </div>
                    <div>
                      <p>{t("Phone")} *</p>
                      <input type="tel" name="phone" />
                    </div>
                    <div>
                      <p>{t("Message")} *</p>
                      <textarea name="message"></textarea>
                    </div>
                    <input type="submit" value="Send" />
                  </form>
                </div>
              </div>
            </Col>
            <Col md="6" sm="12">
              <div className="slideInLeft wow" data-wow-duration="0.5s">
               <iframe
                  title={t("ADDressDes")}
                  src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3458.3928297509783!2d30.86488917554865!3d29.91059107499127!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjnCsDU0JzM4LjEiTiAzMMKwNTInMDIuOSJF!5e0!3m2!1sen!2seg!4v1722806541602!5m2!1sen!2seg" width="100%"
                  height="450"
                  aria-hidden="true"
                  name={t("ADDressDes")}
                  loading="lazy"
                  ></iframe>
                <div>
                  <div className="mt-5">
                    <h5>{t("Address")}:</h5>
                    <p>{t("ADDressDes")}</p>
                  </div>
                  <div className="d-flex mt-5 flex-wrap">
                    <div style={{ marginRight: "20px" }}>
                      <h5>{t("Phone")}:</h5>
                      <p  style={{ direction: "ltr" }}>
                        +20 111 766 6193
                        <br />
                        +20 101 044 6661
                        <br />
                        +20 109 221 0110
                        <br />
                      </p>
                    </div>
                    <div>
                      <h5>{t("Email")}:</h5>

                      <p>
                      ahmedsultan@btwenty-factory.com<br />
                        b-twenty@btwenty-factory.com <br />
                        B.twenty1996@gmail.com
                      </p>
                    </div>
                  </div>
                </div>
            
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="contactsec3">
        <img src="images/map.jpg" alt="" />
        <div className="location"></div>
        <div className="info">
          <h3>{t("Address")}</h3>
          <div className="d-flex my-1">
            <GoLocation />
            <p>{t("ADDressDes")}</p>
          </div>
          <div className="d-flex my-1">
            <BsTelephone />
            <p style={{ direction: "ltr" }}>
              {" "}
              +20 111 766 6193
              <br />
              +20 101 044 6661
              <br />
              +20 109 221 0110
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default ContactUs;
