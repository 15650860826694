import React, { useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import emailjs from "@emailjs/browser";
import { FaFacebookF } from "react-icons/fa";
import { ImLinkedin2 } from "react-icons/im";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Services() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const form = useRef();
  const sendEmail = (e) => {
    alert("message sent successfully");
    e.preventDefault();
    emailjs
      .sendForm(
        "service_ajpha2u",
        "template_srnoztp",
        form.current,
        "svc5eDpjvQRHX5DeK"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <>
      <section className="aboutsec1">
        <div className="fadeInUp wow" data-wow-duration="0.5s">
          <h3>{t("servicesec1")}</h3>
          <p>
          <Link to="/"> {t("Home")} </Link> /  {t("Services")}
          </p>
        </div>
      </section>
      <section className="servicesec1">
        <Container>
          <div className="top-content my-5">
            <div className="title">
              <span className="sub-title">{t("Services")}</span>
              <h2>{t("servicesec1h2")}</h2>
            </div>
            <p>
            {t("servicesec1p")}
            </p>
          </div>
          <Row className="justify-content-center">
            <Col md="4" lg="4" sm="12">
              <div className="item-single mb-30">
                <div className="image">
                  <Link to="/MoldMaking">
                    <img src="images/projects (1).jpeg" alt="Demo Image" />
                  </Link>
                </div>
                <div className="content">
                  <h3>
                    <Link to="/MoldMaking">{t("MoldMaking")}</Link>
                  </h3>
                  <p>
                  {t("MoldMakingp")}                  </p>
                  <div className="cta-btn">
                    <Link to="/MoldMaking" className="btn-text">
                    {t("readmore")}
                    </Link>
                  </div>
                </div>
                <div className="spacer"></div>
              </div>
            </Col>
            <Col md="4" lg="4" sm="12">
              <div className="item-single mb-30">
                <div className="image">
                  <Link to="/CNCMachining">
                    <img src="images/projects (2).jpeg" alt="Demo Image" />
                  </Link>
                </div>
                <div className="content">
                  <h3>
                    <Link to="/CNCMachining">{t("CNCMachining")}</Link>
                  </h3>
                  <p>
                  {t("CNCMachiningp")}                  </p>
                  <div className="cta-btn">
                    <Link to="/CNCMachining" className="btn-text">
                    {t("readmore")}
                    </Link>
                  </div>
                </div>
                <div className="spacer"></div>
              </div>
            </Col>
            <Col md="4" lg="4" sm="12">
              <div className="item-single mb-30">
                <div className="image">
                  <Link to="/PlasticInjection">
                    <img src="images/projects (3).jpeg" alt="Demo Image" />
                  </Link>
                </div>
                <div className="content">
                  <h3>
                    <Link to="/PlasticInjection">{t("PlasticInjection")}</Link>
                  </h3>
                  <p>
                  {t("PlasticInjectionp")}                  </p>
                  <div className="cta-btn">
                    <Link to="/PlasticInjection" className="btn-text">
                    {t("readmore")}
                    </Link>
                  </div>
                </div>
                <div className="spacer"></div>
              </div>
            </Col>
            <Col md="4" lg="4" sm="12">
              <div className="item-single mb-30">
                <div className="image">
                  <Link to="/DesignPrototype">
                    <img src="images/design.jpg" alt="Demo Image" />
                  </Link>
                </div>
                <div className="content">
                  <h3>
                    <Link to="/DesignPrototype">{t("DesignPrototype")}</Link>
                  </h3>
                  <p>
                  {t("DesignPrototypep")}                   </p>
                  <div className="cta-btn">
                    <Link to="/DesignPrototype" className="btn-text">
                    {t("readmore")}
                    </Link>
                  </div>
                </div>
                <div className="spacer"></div>
              </div>
            </Col>
            <Col md="4" lg="4" sm="12">
              <div className="item-single mb-30">
                <div className="image">
                  <Link to="/Consultation">
                    <img src="images/Consultation.jpg" alt="Demo Image" />
                  </Link>
                </div>
                <div className="content">
                  <h3>
                    <Link to="/Consultation">{t("Consultation")}</Link>
                  </h3>
                  <p>
                  {t("Consultationp")}                   </p>
                  <div className="cta-btn">
                    <Link to="/Consultation" className="btn-text">
                    {t("readmore")}
                    </Link>
                  </div>
                </div>
                <div className="spacer"></div>
              </div>
            </Col>
    
          </Row>
        </Container>
      </section>

      <section className="contactsec2">
        <Container>
          <Row>
            <Col md="6" sm="12">
              <div className="slideInRight wow " data-wow-duration="0.5s">
                <div className="contactform">
                  <h3>{t("contacth3")} </h3>
                  <form ref={form} onSubmit={sendEmail}>
                    <div>
                      <p>{t("name")} *</p>
                      <input type="text" name="name" />
                    </div>
                    <div>
                      <p> {t("Company")}* </p>
                      <input type="text" name="company" />
                    </div>
                    <div>
                      <p>{t("Email")} *</p>
                      <input type="email" name="email" />
                    </div>
                    <div>
                      <p>{t("Phone")} *</p>
                      <input type="tel" name="phone" />
                    </div>
                    <div>
                      <p>{t("Message")} *</p>
                      <textarea name="message"></textarea>
                    </div>
                    <input type="submit" value="Send" />
                  </form>
                </div>
              </div>
            </Col>
            <Col md="6" sm="12">
              <div className="slideInLeft wow" data-wow-duration="0.5s">
               <iframe
                  title={t("ADDressDes")}
                  src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3458.3928297509783!2d30.86488917554865!3d29.91059107499127!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjnCsDU0JzM4LjEiTiAzMMKwNTInMDIuOSJF!5e0!3m2!1sen!2seg!4v1722806541602!5m2!1sen!2seg" width="100%"
                  height="450"
                  aria-hidden="true"
                  name={t("ADDressDes")}
                  loading="lazy"
                  ></iframe>
                <div>
                  <div className="mt-5">
                    <h5>{t("Address")}:</h5>
                    <p>{t("ADDressDes")}</p>
                  </div>
                  <div className="d-flex mt-5 flex-wrap">
                    <div style={{ marginRight: "20px" }}>
                      <h5>{t("Phone")}:</h5>
                      <p>
                        +20 111 766 6193
                        <br />
                        +20 101 044 6661
                        <br />
                        +20 109 221 0110
                        <br />
                      </p>
                    </div>
                    <div>
                      <h5>{t("Email")}:</h5>

                      <p>
                      ahmedsultan@btwenty-factory.com<br />
                        b-twenty@btwenty-factory.com <br />
                        B.twenty1996@gmail.com
                      </p>
                    </div>
                  </div>
                </div>
        
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
}

export default Services;
