import React, { useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { PiFactoryThin } from "react-icons/pi";
import { TbClockHour8 } from "react-icons/tb";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper";
import { FaGears } from "react-icons/fa6";
import { BiCategoryAlt } from "react-icons/bi";
import emailjs from "@emailjs/browser";
import Footer from "./Footer";
import { MdDesignServices } from "react-icons/md";
import { IoIosTimer } from "react-icons/io";
import { RiShakeHandsLine } from "react-icons/ri";
import { LuMessagesSquare } from "react-icons/lu";
import { FaFingerprint } from "react-icons/fa";
import { FaRegCalendarCheck } from "react-icons/fa";
import "animate.css";
import "swiper/css/effect-fade";
import "animate.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Home() {
  const form = useRef();
  const sendEmail = (e) => {
    alert("message sent successfully");
    e.preventDefault();
    emailjs
      .sendForm(
        "service_ajpha2u",
        "template_srnoztp",
        form.current,
        "svc5eDpjvQRHX5DeK"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <>
      <section className="homeSec1">
        <div>
          <Swiper
            spaceBetween={30}
            effect={"fade"}
            modules={[EffectFade, Autoplay]}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            loop={true}
            className="mySwiper"
          >
            <SwiperSlide
              style={{ backgroundImage: "url(images/3.png)" }}
              className="animeslide-slide"
            >
              <div className="overlay"></div>
              <div className="container">
                <h1 data-animate="bottom">
                  <span>B</span>.Twenty
                </h1>
                <p data-animate="bottom" className="animeslide-desc">
                  {t("Slide1Home")}
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide
              style={{ backgroundImage: "url(images/1.png)" }}
              className=" animeslide-slide"
            >
              <div className="overlay"></div>

              <div className="container">
                <h1 data-animate="bottom">
                  <span>{t("Slide2HomeH")}</span>
                </h1>
                <p data-animate="bottom" className="animeslide-desc">
                  {t("Slide2Home")}
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide
              style={{
                backgroundImage: "url(images/Untitled-design-6-min.png)",
              }}
              className=" animeslide-slide"
            >
              <div className="overlay"></div>
              <div className="container">
                <h1 data-animate="bottom">
                  <span>{t("Slide3HomeH")}</span>
                </h1>
                <p data-animate="bottom" className="animeslide-desc">
                  {t("Slide3Home")}
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide
              style={{
                backgroundImage:
                  "url(images/scott-graham-5fNmWej4tAA-unsplash.jpg)",
              }}
              className=" animeslide-slide"
            >
              <div className="overlay"></div>
              <div className="container">
                <h1 data-animate="bottom">
                  <span>{t("Slide4HomeH")}</span>
                </h1>
                <p data-animate="bottom" className="animeslide-desc">
                  {t("Slide4Home")}
                </p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
      <section className="homesec2">
        <Container>
          <Row>
            <Col lg="4" md="4" sm="12">
              <div>
                <div className="hoverdiv wow fadeInLeft" data-wow-duration="1s">
                  <div className="hoverdetail">
                    <PiFactoryThin className="detailIcon" />
                    <p>{t("Home2div1")}</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg="4" md="4" sm="12">
              <div>
                <div className="hoverdiv wow fadeInUp" data-wow-duration="1s">
                  <div className="hoverdetail">
                    <TbClockHour8 className="detailIcon" />

                    <p>{t("Home2div2")}</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg="4" md="4" sm="12">
              <div>
                <div className="hoverdiv wow fadeInDown" data-wow-duration="1s">
                  <div className="hoverdetail">
                    <FaGears className="detailIcon" />

                    <p>{t("Home2div3")}</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="homesec3">
        <Container>
          <Row>
            <Col lg="6" md="6" sm="12">
              <div className="wow fadeInRight" data-wow-duration="1s">
                <h5> {t("aboutsec2h5")}</h5>
                <h3>{t("aboutsec2h3")}</h3>
                <ul>
                  <li>
                    <BiCategoryAlt />
                    <p>{t("aboutsec2li2")} </p>
                  </li>
                  <li>
                    <BiCategoryAlt />
                    <p>{t("aboutsec2li1")}</p>
                  </li>

                </ul>
              </div>
            </Col>
            <Col lg="6" md="6" sm="12">
              <div className="sec3imgdiv wow fadeInLeft" data-wow-duration="1s">
                <img src="images/1.png" alt="" className="mainimg" />
                <img
                  src="images/Untitled-design-6-min.png"
                  alt=""
                  className="img1"
                />
                <img
                  src="images/0614ptMultiShot1.jpg"
                  alt=""
                  className="img2"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="homesec4" id="staff">
        <Container>
          <h5>{t("Home4h5")}</h5>
          <h3>{t("Home4h3")}</h3>
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            slidesPerGroup={1}
            // loop={true}
            loopFillGroupWithBlank={true}
            autoplay={{ delay: 2000 }}
            modules={[Autoplay]}
            breakpoints={{
              640: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 3,
              },
            }}
            // modules={[Autoplay]}
            // className="mySwiper clientslider"
            className="mySwiper fadeInUp wow"
            data-wow-duration="0.5s"
          >
            <SwiperSlide>
              <div>
                <img src="images/projects (2).jpeg" alt="" />
                <h3>{t("Slide1Home4")} </h3>
                <p>{t("Slide1Home4p")}</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img src="images/projects (3).jpeg" alt="" />
                <h3> {t("Slide2Home4")}</h3>
                <p>{t("Slide2Home4p")}</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img src="images/projects (4).jpeg" alt="" />
                <h3>{t("Slide3Home4")} </h3>
                <p>{t("Slide3Home4p")}</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img src="images/design.jpg" alt="" />
                <h3>{t("Slide4Home4")}</h3>
                <p>{t("Slide4Home4p")}</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img src="/images/Consultation.jpg" alt="" />
                <h3>{t("Slide5Home4")}</h3>
                <p>{t("Slide5Home4p")}</p>
              </div>
            </SwiperSlide>
          </Swiper>
        </Container>
      </section>
      <section className="homesec33">
        <Container>
          <h3 className="fadeInDown wow span12" data-wow-duration="0.5s">
            {t("homesec33h3")}
          </h3>
          <Row>
            <Col lg="6" md="6" sm="12">
              <div className="fadeInUp wow" data-wow-duration="0.5s">
                <MdDesignServices />
                <div>
                  <h5>{t("homesec33div1h5")}</h5>
                  <p>{t("homesec33div1p")}</p>
                </div>
              </div>
            </Col>
            <Col lg="6" md="6" sm="12">
              <div className="fadeInUp wow" data-wow-duration="0.5s">
                <IoIosTimer />
                <div>
                  <h5>{t("homesec33div2h5")}</h5>
                  <p>{t("homesec33div2p")}</p>
                </div>
              </div>
            </Col>
            <Col lg="6" md="6" sm="12">
              <div
                className="fadeInUp wow"
                data-wow-duration="0.5s"
                data-wow-delay="0.3s"
              >
                <RiShakeHandsLine />
                <div>
                  <h5>{t("homesec33div3h5")}</h5>
                  <p>{t("homesec33div3p")}</p>
                </div>
              </div>
            </Col>
            <Col lg="6" md="6" sm="12">
              <div
                className="fadeInUp wow"
                data-wow-duration="0.5s"
                data-wow-delay="0.4s"
              >
                <FaRegCalendarCheck />
                <div>
                  <h5> {t("homesec33div4h5")} </h5>
                  <p>{t("homesec33div4p")}</p>
                </div>
              </div>
            </Col>
            <Col lg="6" md="6" sm="12">
              <div
                className="fadeInUp wow"
                data-wow-duration="0.5s"
                data-wow-delay="0.3s"
              >
                <LuMessagesSquare />{" "}
                <div>
                  <h5>{t("homesec33div5h5")}</h5>
                  <p>{t("homesec33div5p")}</p>
                </div>
              </div>
            </Col>
            <Col lg="6" md="6" sm="12">
              <div
                className="fadeInUp wow"
                data-wow-duration="0.5s"
                data-wow-delay="0.4s"
              >
                <FaFingerprint />{" "}
                <div>
                  <h5>{t("homesec33div6h5")}</h5>
                  <p>{t("homesec33div6p")}</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <br />
      <br />

      <section className="groupsec " id="DiarBusiness">
        <Container fluid>
          <Row className="align-items-center">
            <Col md="6" sm="12" className="text-center">
              <img
                className="slideInLeft wow"
                data-wow-duration="0.5s"
                src="images/projects (3).jpeg"
                alt=""
              />
            </Col>
            <Col md="6" sm="12" className="grouptext">
              <div className="slideInRight wow" data-wow-duration="0.5s">
                <h3>
                  <span>B.Twenty</span> {t("groupsecdiv1h3")}
                </h3>
                <ul>
                  <li>
                    <BiCategoryAlt />

                    <span>{t("groupsecdiv1li1")}</span>
                  </li>
                  <li>
                    <BiCategoryAlt />

                    <span>{t("groupsecdiv1li2")}</span>
                  </li>
                </ul>
                <Link to="/MoldMaking">{t("readmore")}</Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="groupsec bg" id="Diar">
        <Container fluid>
          <Row className="align-items-center">
            <Col md="6" sm="12" className="grouptext">
              <div className="slideInLeft wow" data-wow-duration="0.5s">
                <h3>
                  <span>B.Twenty</span> {t("groupsecdiv2h3")}
                </h3>
                <ul>
                  <li>
                    <BiCategoryAlt />

                    <span>{t("groupsecdiv2li1")}</span>
                  </li>
                  <li>
                    <BiCategoryAlt />

                    <span>{t("groupsecdiv2li2")}</span>
                  </li>
                  <li>
                    <BiCategoryAlt />

                    <span>{t("groupsecdiv2li3")}</span>
                  </li>
                  <li>
                    <BiCategoryAlt />

                    <span>{t("groupsecdiv2li4")}</span>
                  </li>
                </ul>

                <Link to="/CNCMachining">{t("readmore")}</Link>
              </div>
            </Col>
            <Col md="6" sm="12" className="text-center">
              <img
                className="slideInRight wow"
                data-wow-duration="0.5s"
                src="images/projects (4).jpeg"
                alt=""
              />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="groupsec " id="flash">
        <Container fluid>
          <Row className="align-items-center">
            <Col md="6" sm="12" className="text-center">
              <img
                src="images/projects (6).jpeg"
                className="slideInLeft wow"
                data-wow-duration="0.5s"
                alt=""
              />
            </Col>
            <Col md="6" sm="12" className="grouptext">
              <div className="slideInRight wow" data-wow-duration="0.5s">
                <h3>
                  <span>B.Twenty</span>
                  {t("groupsecdiv3h3")}{" "}
                </h3>
                <ul className="swiperp">
                  <li>
                    <BiCategoryAlt />

                    <span>{t("groupsecdiv3li1")}</span>
                  </li>
                  <li>
                    <BiCategoryAlt />

                    <span>{t("groupsecdiv3li2")}</span>
                  </li>
                </ul>
                <Link to="/PlasticInjection">{t("readmore")}</Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="groupsec bg" id="pixels">
        <Container fluid>
          <Row className="align-items-center">
            <Col md="6" sm="12" className="grouptext">
              <div className="slideInLeft wow" data-wow-duration="0.5s">
                <h3>
                  <span>B.Twenty</span> {t("groupsecdiv4h3")}
                </h3>
                <ul>
                  <li>
                    <BiCategoryAlt />

                    <span>{t("groupsecdiv4li1")}</span>
                  </li>
                  <li>
                    <BiCategoryAlt />

                    <span>{t("groupsecdiv4li2")}</span>
                  </li>
                  <li>
                    <BiCategoryAlt />

                    <span>{t("groupsecdiv4li3")}</span>
                  </li>
                </ul>
                <Link to="/DesignPrototype">{t("readmore")}</Link>
              </div>
            </Col>
            <Col md="6" sm="12" className="text-center">
              <img
                className="slideInRight wow"
                data-wow-duration="0.5s"
                src="images/design.jpg"
                alt=""
              />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="groupsec " id="flash">
        <Container fluid>
          <Row className="align-items-center">
            <Col md="6" sm="12" className="text-center">
              <img
                src="images/Consultation.jpg"
                className="slideInLeft wow"
                data-wow-duration="0.5s"
                alt=""
              />
            </Col>
            <Col md="6" sm="12" className="grouptext">
              <div className="slideInRight wow" data-wow-duration="0.5s">
                <h3>
                  <span>B.Twenty</span> {t("groupsecdiv5h3")}
                </h3>
                <ul className="swiperp">
                  <li>
                    <BiCategoryAlt />

                    <span>{t("groupsecdiv5li1")}</span>
                  </li>
                </ul>
                <Link to="/Consultation">{t("readmore")}</Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <section className="homesec8">
        <Container>
          <div className="sec8div">
            <h5>Customers Opinions </h5>
            <div className="bordersec8"></div>
          </div>

          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            slidesPerGroup={1}
            loop={true}
            loopFillGroupWithBlank={true}
            pagination={true}
            modules={[Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="swipertalk">
                <p>
                  I noticed one thing: it's very productive to discuss a project
                  with pople who have technical background. I forgot how to
                  explain obvious technical things that often happen with more
                  humanitarian people and just get more done.
                </p>
                <div className="swipertalkdiv">
                  <img src="images/user1.webp" alt="" />
                  <div>
                    <h3>Tiffany Daniels</h3>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swipertalk">
                <p>Amazing work and professionalism. Great job.</p>
                <div className="swipertalkdiv">
                  <img src="images/user1.webp" alt="" />
                  <div>
                    <h3>Dimitrios Karakoutas</h3>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swipertalk">
                <p>
                  I hired them for total renovation. They did a great job.
                  Everything I wanted and on time. Will hire them again if i
                  need repairs.
                </p>
                <div className="swipertalkdiv">
                  <img src="images/user1.webp" alt="" />
                  <div>
                    <h3>pbibbo</h3>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </Container>
      </section> */}

      <section className="contactsec2">
        <Container>
          <Row>
            <Col md="6" sm="12">
              <div className="slideInRight wow " data-wow-duration="0.5s">
                <div className="contactform">
                  <h3>{t("contacth3")} </h3>
                  <form ref={form} onSubmit={sendEmail}>
                    <div>
                      <p>{t("name")} *</p>
                      <input type="text" name="name" />
                    </div>
                    <div>
                      <p> {t("Company")}* </p>
                      <input type="text" name="company" />
                    </div>
                    <div>
                      <p>{t("Email")} *</p>
                      <input type="email" name="email" />
                    </div>
                    <div>
                      <p>{t("Phone")} *</p>
                      <input type="tel" name="phone" />
                    </div>
                    <div>
                      <p>{t("Message")} *</p>
                      <textarea name="message"></textarea>
                    </div>
                    <input type="submit" value="Send" />
                  </form>
                </div>
              </div>
            </Col>
            <Col md="6" sm="12">
              <div className="slideInLeft wow" data-wow-duration="0.5s">
                <iframe
                  title={t("ADDressDes")}
                  src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3458.3928297509783!2d30.86488917554865!3d29.91059107499127!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjnCsDU0JzM4LjEiTiAzMMKwNTInMDIuOSJF!5e0!3m2!1sen!2seg!4v1722806541602!5m2!1sen!2seg" width="100%"
                  height="450"
                  aria-hidden="true"
                  name={t("ADDressDes")}
                  loading="lazy"
                  ></iframe>
                <div>
                  <div className="mt-5">
                    <h5>{t("Address")}:</h5>
                    <p>{t("ADDressDes")}</p>
                  </div>
                  <div className="d-flex mt-5 flex-wrap">
                    <div style={{ marginRight: "20px" }}>
                      <h5>{t("Phone")}:</h5>
                      <p style={{ direction: "ltr" }}>
                        +20 111 766 6193
                        <br />
                        +20 101 044 6661
                        <br />
                        +20 109 221 0110
                        <br />
                      </p>
                    </div>
                    <div>
                      <h5>{t("Email")}:</h5>

                      <p>
                        ahmedsultan@btwenty-factory.com<br />
                        b-twenty@btwenty-factory.com <br />
                        B.twenty1996@gmail.com
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <section className="homesec6">
        <Container>
          <h5>Cooperations</h5>
          <h3>
            We are proud to<span> deal with</span>
          </h3>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            slidesPerGroup={1}
            autoplay={{ delay: 1000 }}
            breakpoints={{
              640: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 5,
              },
            }}
            modules={[Autoplay]}
            className="mySwiper clientslider"
          >
            <SwiperSlide>
              <img src="images/Picture7.png" alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="images/Picture8.png" alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="images/Picture9.png" alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="images/Picture10.png" alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="images/Picture11.png" alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="images/Picture9.png" alt="" />
            </SwiperSlide>
          </Swiper>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            slidesPerGroup={1}
            autoplay={{ delay: 1000 }}
            breakpoints={{
              640: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 5,
              },
            }}
            modules={[Autoplay]}
            className="mySwiper clientslider"
          >
            <SwiperSlide>
              <img src="images/Picture12.png" alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="images/Picture13.png" alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="images/Picture14.png" alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="images/Picture15.png" alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="images/Picture16.png" alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="images/Picture11.png" alt="" />
            </SwiperSlide>
          </Swiper>
        </Container>
      </section> */}

      <Footer />
    </>
  );
}

export default Home;
