import React, { useState, useEffect } from "react";
import axios from "axios";
import { Card } from "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "./Footer";
import { BsArrowRight } from "react-icons/bs";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Projects() {
  const [projects, setProjects] = useState([]);
  const [show, setShow] = useState(false);
  const [iddd, setIddd] = useState(0);
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  useEffect(() => {
    axios.get("https://btwenty-factory.com/dashboard/projects/readAll.php").then((res) => {
      setProjects(res.data);
    });
  }, []);
  const handleShowee = (idd = 0) => {
    setIddd(idd );
    setShow(true);
  };

  const handleClose = () => setShow(false);

  return (
    <>
      <section className="aboutsec1">
        <div className="fadeInUp wow" data-wow-duration="0.5s">
          <h3>{t("projectsec1")}</h3>
          <p>
          <Link to="/">  {t("Home")} /  </Link>{t("projects")}
          </p>
        </div>
      </section>
      <section className="projectssec2">
        <h3 className="fadeInDown wow" data-wow-duration="1s">
        {t("projectsec1")}
        </h3>
        <Container>
          <Row>
            {projects.map((project,index) => {
              return (
                <Col
                  md="4"
                  sm="12"
                  key={project.id}
                  onClick={() => handleShowee(index)}
                >
                  <div
                    className="projectdiv bounceIn wow"
                    data-wow-duration="1s"
                    data-wow-delay="o.1s"
                  >
                    <img
                      className="projectimg"
                      src={'https://btwenty-factory.com/uploads/projects/' + project.img}
                      alt="project"
                    />
                    <div className="hoverproject">
                      <p>{t("title")=='name_en'?project.name_en:project.name_ar}</p>
                      <h5>{t("description_en")=='description_en'?project.description_en:project.description_ar}</h5>
                      <BsArrowRight />
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Container>
      </section>
      {/* <section className="projectssec3">
        <Container>
          <Row>
            <Col md="6" sm="12" className="detaildiv">
              <div className="slideInLeft wow" data-wow-duration="1s">
                <h5>Title</h5>
                <p>desc</p>
              </div>
            </Col>
            <Col md="6" sm="12" className="text-center">
              <img
                className="detailimg slideInRight wow"
                data-wow-duration="1s"
                src="images/projects (3).jpeg"
                alt=""
              />
            </Col>
            {/* {projects.slice(7, 16).map((project) => {
              return (
                <Col
                  md="4"
                  sm="12"
                  key={project.id}
                  onClick={() => handleShowee(project.id)}
                >
                  <div
                    className="projectdiv bounceInUp wow span3"
                    data-wow-duration="1s"
                  >
                    <img
                      className="projectimg"
                      src="images/projects (2).jpeg"
                      alt="land scape"
                    />
                    <div className="hoverproject">
                      <p>{project.title}</p>
                      <BsArrowRight />
                    </div>
                  </div>
                </Col>
              );
            })} 
          </Row>
        </Container>
      </section> */}
      {show ? (
        <Modal
          show={show}
          onHide={handleClose}
          className="projectmodal"
          centered
        >
          <Modal.Body>
            <div
              className="mySwiper"
            >
              {(projects[iddd].video).length>4 ?(<div><video src={'https://btwenty-factory.com/uploads/projects/' + projects[iddd].video} controls="" width="400px"></video></div>)
              : projects[iddd].img? (
                <div>
                  <img width="400px" src={'https://btwenty-factory.com/uploads/projects/' + projects[iddd].img} alt="" />
                </div>
              ) : null}
             
           
            </div>
          </Modal.Body>
        </Modal>
      ) : null}
      <Footer />
    </>
  );
}

export default Projects;
